import { useHistory } from 'react-router-use-history'
import { useSetRecoilState } from 'recoil'
import { getCookie, setCookie } from 'typescript-cookie'
import jwtDecode from 'jwt-decode'

import { useRouter } from 'hooks/router'
import { clientNameAtom, userAtom } from 'recoil/atoms'
import { reqKy } from './api'
import { useThemeStore } from './theme'
import { api, cookies, urls } from 'utils/constants'

/** Verify if the token is still valid. */
const isValidToken = (token) => {
    const now = new Date(Date.now())
    now.setMinutes(now.getMinutes() + 2)

    const dateNow = Math.ceil(now.getTime() / 1_000)
    const decodedToken = jwtDecode(token)

    if (!decodedToken || !decodedToken.exp) return false

    return decodedToken.exp > dateNow
}

export const useUserStore = () => {
    const history = useHistory()
    const router = useRouter()

    const setUser = useSetRecoilState(userAtom)
    const setClientName = useSetRecoilState(clientNameAtom)
    const { updateTheme } = useThemeStore()

    /** Update tokens. */
    const updateTokens = async () => {
        const accessToken = getCookie(cookies.ACCESS_TOKEN)
        const refreshToken = getCookie(cookies.REFRESH_TOKEN)
        const path = new URL(window.location.href).pathname
        const isAuthPage =
            path.includes(urls.LOGIN) || path.includes(urls.REGISTER)

        if (!!accessToken && isValidToken(accessToken)) return

        if ((!refreshToken || !isValidToken(refreshToken)) && !isAuthPage) {
            await router.push(urls.LOGIN)

            return
        }

        const requestRefresh = await reqKy
            .post(api.auth.REFRESH_TOKENS, {
                json: { refresh: refreshToken },
            })
            .json()
            .catch(() => {
                /* Empty */
            })

        if (!requestRefresh) return

        setCookie(cookies.ACCESS_TOKEN, requestRefresh.access, cookies.OPTIONS)

        setCookie(
            cookies.REFRESH_TOKEN,
            requestRefresh.refresh,
            cookies.OPTIONS,
        )
    }

    const initialiseUser = async (setTheme) => {
        const path = new URL(window.location.href).pathname

        if (path.startsWith('/activation')) return

        await updateTokens()

        const accessToken = getCookie(cookies.ACCESS_TOKEN)

        const requestUser = await reqKy
            .get(api.user.PROFILE, {
                headers: {
                    Authorization: `JWT ${accessToken}`,
                },
            })
            .json()
            .catch(() => {
                /* Empty */
            })

        if (!requestUser) {
            const client = path.split('/')[1]

            await updateTheme(client, setTheme)

            return
        }

        setUser((oldUser) => ({
            ...oldUser,
            id: requestUser[0].id,
            credit: requestUser[0].credit,
            firstName: requestUser[0].first_name,
            ...(requestUser[0].card_id !== null && {
                cardId: requestUser[0].card_id,
            }),
            ...(requestUser[0].site_customer && {
                client: requestUser[0].site_customer,
            }),
        }))

        const clientNameValue = requestUser[0].client.name

        const pathToSend = path === `/${clientNameValue}` ? '' : path

        await updateTheme(clientNameValue, setTheme)
        setClientName(clientNameValue)

        if (path.startsWith('/log/')) return

        if (
            requestUser &&
            (path.includes(urls.LOGIN) || path.includes(urls.REGISTER))
        ) {
            await history.push(`/${clientNameValue}`)

            return
        }

        if (!path.startsWith(`/${clientNameValue}`)) {
            await history.push(`/${clientNameValue}${pathToSend}`)
        }
    }

    const updateCredit = async () => {
        await updateTokens()

        const accessToken = getCookie(cookies.ACCESS_TOKEN)

        const requestMe = await reqKy
            .get(api.user.PROFILE, {
                headers: {
                    Authorization: `JWT ${accessToken}`,
                },
            })
            .json()
            .catch(() => {
                /* Empty */
            })

        if (!requestMe) return

        setUser((oldUser) => ({
            ...oldUser,
            credit: requestMe[0].credit,
        }))
    }

    return {
        initialiseUser,
        updateTokens,
        updateCredit,
    }
}
