export const urls = {
    ACCOUNT: '/account',
    ACTIVATION: '/activation',
    DISPUTES: '/disputes',
    HOMEPAGE: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    REFILL: '/refill',
    REFILLS: '/refills',
    STOCKS: '/stocks',

    purchases: {
        DETAILS: '/purchases/:id',
    },
}
