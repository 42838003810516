import { lazy } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import Loadable from 'ui-component/Loadable'
import { urls } from 'utils/constants'

const Register = Loadable(lazy(() => import('pages/register')))
const Activation = Loadable(lazy(() => import('pages/activation')))
const Login = Loadable(lazy(() => import('pages/login')))
const Account = Loadable(lazy(() => import('pages/account')))

const Homepage = Loadable(lazy(() => import('pages/index')))
const PurchasesDetails = Loadable(lazy(() => import('pages/purchases/[id]')))
const Refill = Loadable(lazy(() => import('pages/refill')))
const Refills = Loadable(lazy(() => import('pages/refills')))
const Stocks = Loadable(lazy(() => import('pages/stocks')))
const Disputes = Loadable(lazy(() => import('pages/disputes/index')))
const DisputeDetails = Loadable(lazy(() => import('pages/disputes/[id]')))
const QrCode = Loadable(lazy(() => import('pages/qr-code')))

const routes = (isLoggedIn, setTheme) => [
    {
        path: ':clientName',
        element: <Outlet />,
        children: [
            {
                path: 'login',
                element: <Login setTheme={setTheme} />,
            },
            {
                path: 'register',
                element: <Register setTheme={setTheme} />,
            },
        ],
    },
    {
        path: ':clientName',
        element: isLoggedIn ? <Outlet /> : <Navigate to='login' />,

        children: [
            {
                path: '',
                element: <Homepage />,
            },
            {
                path: 'refills',
                element: <Refills />,
            },
            {
                path: 'disputes',
                element: <Disputes />,
            },
            {
                path: 'dispute/:id',
                element: <DisputeDetails />,
            },
            {
                path: 'stocks',
                element: <Stocks />,
            },
            {
                path: 'refill',
                element: <Refill />,
            },
            {
                path: 'account',
                element: <Account />,
            },
            {
                path: 'purchases/:id',
                element: <PurchasesDetails />,
            },
            {
                path: '*',
                element: isLoggedIn ? (
                    <Navigate to='' />
                ) : (
                    <Navigate to={urls.LOGIN} />
                ),
            },
        ],
    },
    {
        path: 'log',
        element: isLoggedIn ? (
            <Outlet />
        ) : (
            <Navigate
                to='/login'
                /* eslint-disable-next-line no-restricted-globals */
                state={{ prevPath: location.pathname }}
            />
        ),

        children: [
            {
                path: 'login_qr_code/:client_id/:machine_id',
                element: <QrCode />,
            },
        ],
    },
    {
        path: '/',
        element: <Outlet />,
        children: [
            {
                path: 'login',
                element: <Login setTheme={setTheme} />,
            },
            {
                path: 'activation',
                element: <Activation setTheme={setTheme} />,
            },
            {
                path: '',
                element: <Navigate to={urls.LOGIN} />,
            },
        ],
    },
]

export default routes
