import { atom } from 'recoil'

import logo from 'assets/images/Logo_Konvives.png'

export const userAtom = atom({
    key: 'userAtom',
    default: {
        id: -1,
        firstName: '',
        credit: 0,
        cardId: -1,
        client: -1,
    },
})

/**
 * Global snackbar type.
 * @typedef {Object} GlobalSnackbarAtom
 * @property {Dispatch<SetStateAction<boolean>>} setShow
 * @property {'info' | 'success' | 'error'} [variant]
 * @property {string} [text]
 */
export const globalSnackberAtom = atom({
    key: 'globalSnackbarAtom',
    /** @type GlobalSnackbarAtom */
    default: {
        setShow: () => {
            /* Empty */
        },
    },
})

export const logoAtom = atom({
    key: 'logoAtom',
    default: logo,
})

export const clientNameAtom = atom({
    key: 'clientNameAtom',
    default: undefined,
})
