import { useHistory } from 'react-router-use-history'
import { useRecoilState } from 'recoil'

import { reqKy } from './api'
import { logoAtom } from 'recoil/atoms'
import { api, urls } from 'utils/constants'
import { themeClient } from 'utils/theme'

export const useThemeStore = () => {
    const history = useHistory()

    const [logo, setLogo] = useRecoilState(logoAtom)

    const updateTheme = async (clientName, setTheme) => {
        if (typeof clientName === 'string' && clientName !== 'activation') {
            const requestTheme = await reqKy
                .get(api.KONSOLE_COLORS, {
                    searchParams: { client__name: clientName },
                })
                .json()
                .catch(() => {
                    /*Empty*/
                })

            if (!requestTheme || !requestTheme.results.length) {
                history.push(urls.LOGIN)

                return
            }

            const updatedTheme = themeClient({
                primary: requestTheme.results[0].primary,
                secondary: requestTheme.results[0].secondary,
            })

            setLogo(requestTheme.results[0].logo_konvive)
            setTheme(updatedTheme)
        }
    }

    return {
        updateTheme,
        logo,
    }
}
