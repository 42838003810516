import { Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { links } from 'utils/constants'
import { various } from 'utils/theme'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 'auto',
        padding: various.padding,
        backgroundColor: theme.palette.primary.light,
    },

    link: {
        color: theme.palette.secondary.main,
        textDecorationColor: theme.palette.secondary.main,
    },
}))

export const Footer = () => {
    const classes = useStyles()

    return (
        <footer className={classes.root}>
            <Link
                className={classes.link}
                href={links.E_THIK}
                target='_blank'
                rel='noreferrer'
            >
                {'e-thik'}
            </Link>
        </footer>
    )
}
