import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import { Avatar, Button, Link } from '@mui/material'
import { Menu } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import { Flex } from 'ui'
import { ChooseLangage } from './ChooseLangage'

import { useRouter } from 'hooks/router'
import { logoAtom, userAtom } from 'recoil/atoms'
import { urls } from 'utils/constants'
import { joinStyles, various } from 'utils/theme'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 100,
        position: 'sticky',
        top: '0',
        padding: `10px ${various.padding}`,
        backgroundColor: theme.palette.primary.light,
    },

    header: {
        justifyContent: 'space-between',
        width: '100%',
    },

    menu: {
        minWidth: 'unset',
        padding: '10px',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: various.borderRadius,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.light,
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    homepage: {
        cursor: 'pointer',
        display: 'flex',
    },

    logo: {
        maxWidth: '50px',
        maxHeight: '50px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '60px',
            maxHeight: '60px',
        },
    },

    group: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },

    link: {
        margin: '10px',
        padding: '5px',
        fontSize: '1rem',
        color: theme.palette.secondary.main,
    },

    setting: {
        height: '45px',
        margin: various.padding,
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        borderRadius: various.borderRadius,
    },

    avatar: {
        height: '25px',
        width: '25px',
    },

    name: {
        paddingLeft: '10px',
    },

    linkNavigation: {
        margin: '5px 0',
    },

    settingsNavigation: {
        justifyContent: 'space-around',
    },
}))

export const Header = () => {
    const classes = useStyles()
    const router = useRouter()
    const { t } = useTranslation()

    const logo = useRecoilValue(logoAtom)
    const { client, firstName } = useRecoilValue(userAtom)

    const [wantsNavigate, setWantsNavigate] = useState(false)

    const links = [
        {
            name: t('refill'),
            url: urls.REFILL,
        },
        {
            name: t('refills'),
            url: urls.REFILLS,
        },
        ...(client > -1
            ? [
                  {
                      name: t('stocks'),
                      url: urls.STOCKS,
                  },
              ]
            : []),
        {
            name: t('disputes'),
            url: urls.DISPUTES,
        },
    ]

    return (
        <header className={classes.root}>
            <Flex className={classes.header}>
                {/* Homepage button */}
                <Link
                    className={classes.homepage}
                    variant='button'
                    onClick={() => {
                        setWantsNavigate(false)
                        router.push(urls.HOMEPAGE)
                    }}
                >
                    <img
                        className={classes.logo}
                        src={logo}
                        alt='Client Logo'
                    />
                </Link>

                {/* Show navigation bar (for mobile) */}
                <Button
                    className={classes.menu}
                    onClick={() => {
                        setWantsNavigate(!wantsNavigate)
                    }}
                >
                    <Menu />
                </Button>

                {/* Links available */}
                <Flex className={classes.group}>
                    {links.map((link) => (
                        <Button
                            className={classes.link}
                            onClick={() => {
                                router.push(link.url)
                            }}
                        >
                            {link.name}
                        </Button>
                    ))}
                </Flex>

                {/* Settings */}
                <Flex className={classes.group}>
                    {/* Language */}
                    <ChooseLangage />

                    {/* Account */}
                    <Button
                        className={classes.setting}
                        variant='outlined'
                        onClick={() => router.push(urls.ACCOUNT)}
                    >
                        <Avatar
                            className={classes.avatar}
                            src={undefined}
                        />

                        <div className={classes.name}>{firstName}</div>
                    </Button>
                </Flex>
            </Flex>

            {wantsNavigate && (
                <div>
                    {/* Links available */}
                    {links.map((link) => (
                        <div>
                            <Button
                                className={joinStyles(
                                    classes.link,
                                    classes.linkNavigation,
                                )}
                                onClick={() => {
                                    setWantsNavigate(false)
                                    router.push(link.url)
                                }}
                            >
                                {link.name}
                            </Button>
                        </div>
                    ))}

                    <Flex className={classes.settingsNavigation}>
                        {/* Language */}
                        <ChooseLangage setWantsNavigate={setWantsNavigate} />

                        {/* Account */}
                        <Button
                            className={classes.setting}
                            variant='outlined'
                            onClick={() => {
                                setWantsNavigate(false)
                                router.push(urls.ACCOUNT)
                            }}
                        >
                            <Avatar
                                className={classes.avatar}
                                src={undefined}
                            />

                            <div className={classes.name}>{firstName}</div>
                        </Button>
                    </Flex>
                </div>
            )}
        </header>
    )
}
