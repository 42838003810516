import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources: {
            'en-GB': {
                translations: {
                    accountCreated:
                        'Account created! Check your email to activate your account.',
                    addCard: 'Add a card',
                    alreadyHaveAccount: 'I already have an account',
                    amount: 'Amount :',
                    cardRegistered: 'Card successfully registered!',
                    cartDiscountYes: 'Cart discount : yes',
                    changeAmount: 'Change amount',
                    chooseAFile: 'Choose a file…',
                    confirmation: 'Confirmation',
                    contact: 'contact',
                    contactless: 'contactless',
                    createAccount: 'Create an account',
                    credit: 'Credit',
                    creditCard: 'Credit card',
                    discounts: 'Discounts',
                    disputed: 'Disputed',
                    disputes: 'Disputes',
                    email: 'Email',
                    emailAlreadyUsed: 'Email already used.',
                    employerContribution: 'Employer contribution :',
                    english: 'English',
                    errorTryLater:
                        'An error has occurred, please try again later.',
                    firstName: 'First name',
                    french: 'French',
                    globalInformation: 'Global information',
                    iAcceptTos: 'I accept the ToS.',
                    information: 'Information',
                    insufficientCredit: 'Insufficient credit',
                    lastName: 'Last name',
                    login: 'Login',
                    logout: 'Logout',
                    machineNotFound: 'The machine has not been found.',
                    menus: 'Menus',
                    modify: 'Modify',
                    newPassword: 'New password',
                    noFileChosen: 'No file chosen.',
                    noProduct: 'No product',
                    noPurchaseFound: 'No purchase found.',
                    noRefillFound: 'No refill found.',
                    noValidCard: 'No valid card',
                    oldPassword: 'Old password',
                    oldPasswordIncorrect: 'The old password is incorrect.',
                    open: 'Open',
                    opened: 'Successfully opened!',
                    password: 'Password',
                    passwordConfirmation: 'Password confirmation',
                    passwordsMustBeIdentical: 'Passwords must be identical.',
                    passwordsMustBeStronger: 'The password must be stronger.',
                    phoneNumber: 'Phone number',
                    price: 'Price',
                    privateCard: 'Private card',
                    products: 'Products',
                    productsPurchased: 'Products purchased',
                    purchase: 'Purchase',
                    purchaseDetails: 'Purchase details',
                    purchases: 'Purchases',
                    qrCode: 'QR code',
                    refill: 'Refill',
                    refillHistory: 'Refill history',
                    refilling: 'Refilling',
                    refills: 'Refills',
                    register: 'Register',
                    save: 'Save',
                    spanish: 'Spanish',
                    stocks: 'Stocks',
                    updated: 'Successfully updated!',
                    emailOrPasswordIncorrect: 'Incorrect e-mail or password.',
                    vat: 'VAT',
                    wantsDiscounts: 'I would like to receive special offers.',
                    wantsMarketing: 'I would like to receive targeted emails.',
                    wantsNewsletters: 'I would like to receive newsletters.',

                    // Old
                    add: 'Add',
                    firstTimeText:
                        'To use the machines, please add a credit card.',
                    welcome: 'Welcome !',
                    'Forgot Password?': 'Forgot Password?',
                    'First name': 'First name',
                    'Last name': 'Last name',
                    Username: 'Username',
                    'E-mail': 'E-mail',
                    'Sign up': 'Sign up',
                    'Sign in': 'Sign in',
                    French: 'French',
                    English: 'English',
                    Spanish: 'Spanish',
                    'Live Customize': 'Live Customize',
                    Language: 'Language',
                    'Enter your credentials to continue':
                        'Enter your credentials to continue',
                    'Hi, Welcome Back': 'Hi, Welcome Back',
                    'Create your Konvive account':
                        'Create your Konvive account',
                    Dashboard: 'Dashboard',
                    Contestation: 'Disputes',
                    History: 'History',
                    Name: 'Name',
                    Price: 'Price',
                    refillAlready: 'A refill is in progress, please wait.',
                    supply: 'supply',
                    punctual: 'Punctual reloading',
                    refillCard: 'Refill private card',
                    registerError:
                        'An error has occurred while registering your card ',
                    successRefill:
                        'Your refill request has been successfully processed',
                    failedRefill:
                        'Your refill request failed, please try again',
                    paymentSuccess: 'Your refill has been successfull',
                    paymentFailed: 'Your refill has been refused',
                    Disputes: 'Disputes',
                    'Good Morning': 'Good Morning',
                    Logout: 'Logout',
                    'Account Settings': 'Account Settings',
                    'Create new account': 'Create new account',
                },
            },

            'fr-FR': {
                translations: {
                    accountCreated:
                        'Compte créé ! Regardez vos courriels pour activer votre compte.',
                    addCard: 'Ajouter une carte',
                    alreadyHaveAccount: "J'ai déjà un compte",
                    amount: 'Montant :',
                    cardRegistered: 'Carte enregistrée avec succès !',
                    cartDiscountYes: 'Réduction panier : oui',
                    changeAmount: 'Changer de montant',
                    chooseAFile: 'Choisir un fichier…',
                    confirmation: 'Confirmation',
                    contact: 'avec contact',
                    contactless: 'sans contact',
                    createAccount: 'Créer un compte',
                    credit: 'Crédit',
                    creditCard: 'Carte de crédit',
                    discounts: 'Réductions',
                    disputed: 'Contesté',
                    disputes: 'Contestations',
                    email: 'Courriel',
                    emailAlreadyUsed: 'Courriel déjà utilisé.',
                    employerContribution: 'Participation employeur :',
                    english: 'Anglais',
                    errorTryLater:
                        'Une erreur est survenue, veuillez réessayer plus tard.',
                    firstName: 'Prénom',
                    french: 'Français',
                    globalInformation: 'Informations globales',
                    iAcceptTos: "J'accepte les CGU.",
                    information: 'Informations',
                    insufficientCredit: 'Crédit insuffisant',
                    lastName: 'Nom',
                    login: 'Connexion',
                    logout: 'Déconnexion',
                    machineNotFound: "La machine n'a pas été trouvée.",
                    menus: 'Menus',
                    modify: 'Modifier',
                    newPassword: 'Nouveau mot de passe',
                    noFileChosen: 'Aucun fichier choisi.',
                    noProduct: 'Aucun produit',
                    noPurchaseFound: 'Aucun achat trouvé.',
                    noRefillFound: 'Aucun rechargement trouvé.',
                    noValidCard: 'Pas de carte valide',
                    oldPassword: 'Ancien mot de passe',
                    oldPasswordIncorrect:
                        "L'ancien mot de passe est incorrect.",
                    open: 'Ouvrir',
                    opened: 'Ouvert avec succès !',
                    password: 'Mot de passe',
                    passwordConfirmation: 'Confirmation mot de passe',
                    passwordsMustBeIdentical:
                        'Les mots de passe doivent être identiques.',
                    passwordsMustBeStronger:
                        'Le mot de passe doit être plus robuste.',
                    phoneNumber: 'Numéro de téléphone',
                    price: 'Prix',
                    privateCard: 'Carte privative',
                    products: 'Produits',
                    productsPurchased: 'Produits achetés',
                    purchase: 'Achat',
                    purchaseDetails: "Détails de l'achat",
                    purchases: 'Achats',
                    qrCode: 'QR code',
                    refill: 'Recharger',
                    refillHistory: 'Historique des rechargements',
                    refilling: 'Rechargement',
                    refills: 'Rechargements',
                    register: 'Inscription',
                    save: 'Sauvegarder',
                    spanish: 'Espagnol',
                    stocks: 'Stocks',
                    updated: 'Mis à jour avec succès !',
                    emailOrPasswordIncorrect:
                        'Courriel ou mot de passe incorrect.',
                    vat: 'TVA',
                    wantsDiscounts: 'Je souhaite recevoir les promotions.',
                    wantsMarketing:
                        'Je souhaite recevoir des courriels ciblés.',
                    wantsNewsletters:
                        "Je souhaite recevoir les bulletins d'information.",

                    // Old
                    add: 'Ajouter',
                    firstTimeText:
                        'Afin de consommer sur les machines, veuillez ajouter une carte de crédit.',
                    welcome: 'Bienvenue !',
                    'Forgot Password?': 'Mot de passe oublié?',
                    'First name': 'Prénom',
                    'Last name': 'Nom',
                    Username: 'Identifiant',
                    'E-mail': 'E-mail',
                    'Sign up': 'Créer compte',
                    'Sign in': 'Connexion',
                    French: 'Français',
                    English: 'Anglais',
                    Spanish: 'Espagnol',
                    'Live Customize': 'Customisation en live',
                    Language: 'Langue',
                    Name: 'Nom',
                    Price: 'Prix',
                    Description: 'Description',
                    'Create your Konvive account': 'Crée votre compte Konvive',
                    'Enter your credentials to continue':
                        'Connectez vous pour continuer',
                    'Hi, Welcome Back':
                        'Bonjour ! Bienvenue dans votre Espace Konvive',
                    Dashboard: 'Tableau de bord',
                    History: 'Historique',
                    'Stock Machine': ' Stocks des Machines',
                    'Select a machine': 'Séléctionnez une machine',
                    Disputes: 'Contestations',
                    'Good Morning': 'Bonjour',
                    Logout: 'Déconnexion',
                    'Account Settings': 'Paramètres compte',
                    'Create new account': 'Créer un nouveau compte',
                    'Account created. Please check your mail to activate your account.':
                        'Compte créé. Veuillez vérifier votre courrier pour activer votre compte',
                    'Name card': 'Nom carte',
                    'Card number': 'Numéro de carte',
                    'Expiration date': "Date d'expiration",
                    'EXPIRATION MONTH': "MOIS D'EXPIRATION",
                    'EXPIRATION YEAR': "ANNEE D'EXPIRATION",
                    'Add Card': 'Ajouter la carte',
                    CVV: 'CVV',
                    'Account Activated': 'Compte Activé',
                    'Import a card to continue':
                        'Importez une carte bancaire pour continuer',
                    'Save card': 'Enregistrer carte',
                    'Paid with': 'Payé par',
                    'No menu/promo for this sale':
                        'Aucun menu/promo pour cette vente',
                    Products: 'Produits',
                    ' products': ' produits',
                    '1 product': '1 produit',
                    VAT: 'TVA',
                    'Previous credit': 'Crédit précédent',
                    'Current credit': 'Crédit actuel',
                    'Client number': 'Numéro de client',
                    'Contact less': 'Sans contact',
                    'Credit card': 'Carte bancaire',
                    'Mobile app': 'App mobile',
                    '30$': '30€',
                    '40$': '40€',
                    '50$': '50€',
                    '100$': '100€',
                    'Private card': 'Carte privative',
                    'Digital luncheon voucher': 'TRD',
                    'Sale details': 'Détails vente',
                    'See dispute': 'Voir contestation',
                    'Sales history': 'Historique des ventes',
                    'Refills history': 'Historique des rechargements',
                    refillCard: 'Recharger la carte privative',
                    refillAlready:
                        'Un rechargement est en cours, veuillez patienter.',
                    Reload: 'Recharger',
                    registerError:
                        "Une erreur s'est produite lors de l'enregistrement de votre carte",
                    successRefill:
                        'Votre demande de rechargement a été traitée avec succès',
                    failedRefill:
                        'Votre demande de rechargement a échoué, veuillez réessayer',
                    paymentSuccess:
                        'Votre recharge a été effectuée avec succès',
                    paymentFailed: 'Votre recharge a été refusée',
                    punctual: 'Rechargement ponctuel',
                    'Your refill request has been successfully processed':
                        'Votre demande de rechargement a été effectué avec succès',
                    'Your payment failed, please try again':
                        'Votre paiement a échoué, veuillez Réessayer',
                    'Bonus of 12$': 'Bonus de 12€',
                    'Bonus of 5$': 'Bonus de 5€',
                    discounted: 'de réduction',
                    'Refill amount': 'Montant rechargement',
                    'Current total credit': 'Crédit total actuel',
                    'See more': 'Voir plus',
                    and: 'et',
                    'in participation': 'en participation',
                    'Total product bought': 'Nombre de produits achetés',
                    'Total money spent': 'Argent total dépensé',
                    'Total sales': 'Nb de transactions',
                    'No sale found': 'Aucune transaction trouvée',
                    'Payment methods list': 'Liste méthodes de paiement',
                    'Add payment method': 'Ajouter méthode de paiement',
                    'Meal voucher Conecs': 'TRD',
                    Profile: 'Profil',
                    'Save details': 'Enregistrer',
                    'Reset password': 'Réinitialiser mot de passe',
                    'Account from': 'Compte de',
                    'Phone number': 'Numéro de téléphone',
                    'E-mail address': 'Adresse e-mail',
                    'Upload picture': 'Importer une photo',
                    'Add a payment method': 'Ajouter une méthode de paiement',
                    'After refund credit': 'Crédit après remboursement',
                    'Before refund credit': 'Crédit avant remboursement',
                    'Are you sure you want to delete this payment method ?':
                        'Êtes-vous sûr de vouloir supprimer cette méthode de paiement ?',
                    Delete: 'Supprimer',
                    Cancel: 'Annuler',
                    Close: 'Fermer',
                    Reason: 'Raison',
                    'Your card has been registered.':
                        'Votre carte à bien été enrtegister.',
                    Refund: 'Remboursement',
                    'Your account has been successfully activated!':
                        'Votre compte a été activé avec succès !',
                    'You will be redirected to the login page in a few seconds...':
                        'Vous serez redirigé vers la page de connexion dans quelques secondes...',
                    'Activation Error ': "Erreur d 'activation",
                    Hello: 'Bonjour',
                    'Created successfully': 'Créé avec succès',
                    'Updated successfully': 'Mis à jour avec succès',
                    'Deleted successfully': 'Supprimé avec succès',
                    'Selected Product Details':
                        'Détails du produit sélectionné',
                    'After refill credit': 'Crédit après rechargement',
                    "I'm willing to receive every week the newsletter from ":
                        'Je souhaite recevoir chaque semaine la newsletter de ',
                    "I'm willing to receive punctual promotions (sales, operations, Antiwaste)":
                        'Je souhaite recevoir des promotions ponctuelles (soldes, opérations, Antigaspi...).',
                    "I'm willing to receive targeted emails according to my browsing data and my interests":
                        'Je souhaite recevoir des emails ciblés en fonction de mes données de navigation et mes intérêts.',
                    'No dispute found': 'Aucune contestation trouvée',
                    'Old password': 'Ancien mot de passe',
                    'New password': 'Nouveau mot de passe',
                    'Reset your password': 'Réinitialiser votre mot de passe',
                    'Confirm password': 'Confirmation nouveau mot de passe',
                    'Please validate the login request by clicking the button':
                        'Veuillez cliquer sur le bouton pour valider la demande de connexion',
                    'Send connection request': 'Envoyer demande de connexion',
                    'Logged in to machine successfully! You are being redirected to the app...':
                        "Connexion à la machine réussie! Nous vous redirigeons vers l'application...",
                    'You need to register at least one paiement method to use the app.':
                        "Vous devez enregistrer au moins une méthode de paiement pour utiliser l'app.",
                    'Unable to registered your card, please check the information. Please try again.':
                        "Impossible d'enregistrer votre carte, veuillez vérifier les informations. Veuillez réessayer.",
                    'No refill found': 'Aucun rechargement trouvé',
                    Payment: 'Paiement',
                    'Invalid card': 'Carte invalide',
                    'Contact operator of this sale':
                        'Contacter le gestionnaire de cette vente',
                    'The application cannot find which client you are trying to create an account for. Please initiate the creation via a QR code or a link sent to you by an operator.':
                        "L'application ne peut trouver de client auquel vous relier. Veuillez initier la création depuis un QR code ou un lien envoyé par un gestionnaire.",
                },
            },

            'es-ES': {
                translations: {
                    accountCreated:
                        '¡Cuenta creada! Compruebe su correo electrónico para activar su cuenta.',
                    addCard: 'Añadir una tarjeta',
                    alreadyHaveAccount: 'Ya tengo una cuenta',
                    amount: 'Importe:',
                    cardRegistered: '¡Tarjeta registrada con éxito!',
                    cartDiscountYes: 'Descuento cesta: sí',
                    changeAmount: 'Modificar importe',
                    chooseAFile: 'Elige un archivo…',
                    confirmation: 'Confirmación',
                    contact: 'por contacto',
                    contactless: 'sin contacto',
                    createAccount: 'Crear una cuenta',
                    credit: 'Crédito',
                    creditCard: 'Tarjeta de crédito',
                    discounts: 'Reducciones',
                    disputed: 'Disputado',
                    disputes: 'Disputas',
                    email: 'Correo electrónico',
                    emailAlreadyUsed: 'Correo electrónico ya en uso.',
                    employerContribution: 'Contribución patronal:',
                    english: 'Inglés',
                    errorTryLater:
                        'Se ha producido un error, inténtelo de nuevo más tarde.',
                    firstName: 'Apellidos',
                    french: 'Francés',
                    globalInformation: 'Información global',
                    iAcceptTos: 'Acepto las condiciones generales.',
                    information: 'Información',
                    insufficientCredit: 'Crédito insuficiente',
                    lastName: 'Nombre',
                    login: 'Acceso',
                    logout: 'Desconexión',
                    machineNotFound: 'La máquina no ha sido encontrada.',
                    menus: 'Menús',
                    modify: 'Modificar',
                    newPassword: 'Nueva contraseña',
                    noFileChose: 'No hay ficheros seleccionados.',
                    noProduct: 'No productos',
                    noPurchaseFound: 'No se encontraron compras.',
                    noRefillFound: 'No se encontraron recargas.',
                    noValidCard: 'Sin tarjeta válida',
                    oldPassword: 'Contraseña antigua',
                    oldPasswordIncorrect:
                        'La contraseña antigua es incorrecta.',
                    open: 'Abrir',
                    opened: '¡Abierto con éxito!',
                    password: 'Contraseña',
                    passwordConfirmation: 'Confirmar contraseña',
                    passwordsMustBeIdentical:
                        'Las contraseñas deben ser idénticas.',
                    passwordsMustBeStronger:
                        'La contraseña debe ser más segura.',
                    phoneNumber: 'Número de teléfono',
                    price: 'Precio',
                    privativeCard: 'Tarjeta privada',
                    products: 'Productos',
                    productsPurchased: 'Productos adquiridos',
                    purchase: 'Compra',
                    purchaseDetails: 'Detalles de la compra',
                    purchases: 'Compras',
                    qrCode: 'Código QR',
                    refill: 'Recargar',
                    refillHistory: 'Recargar historial',
                    refilling: 'Recargado',
                    refills: 'Recargas',
                    register: 'Inscripción',
                    save: 'Guardar',
                    spanish: 'Español',
                    stocks: 'Stocks',
                    updated: '¡Actualizado con éxito!',
                    emailOrPasswordIncorrect:
                        'Correo electrónico o contraseña incorrecto.',
                    vat: 'IVA',
                    wantsDiscounts: 'Me gustaría recibir ofertas especiales.',
                    wantsMarketing:
                        'Me gustaría recibir correos electrónicos específicos.',
                    wantsNewsletters:
                        'Me gustaría recibir boletines informativos.',

                    // Old
                    add: 'Añadir',
                    firstTimeText:
                        'Para utilizar las máquinas, añada una tarjeta de crédito.',
                    welcome: 'Bienvenido !',
                    'Forgot Password?': '¿Olvidó su contraseña?',
                    'First name': 'Nombre de pila',
                    'Last name': 'Apellido',
                    Username: 'Nombre de usuario',
                    Credit: 'Crédito',
                    'E-mail': 'Correo electrónico',
                    'Transaction #': 'Transacción #',
                    Send: 'Envíe',
                    'Type your message here': 'Escriba aquí su mensaje',
                    'Sign up': 'Registrarse',
                    'Sign in': 'Iniciar sesión',
                    French: 'Francés',
                    English: 'Inglés',
                    Spanish: 'Español',
                    'Live Customize': 'Personalización en vivo',
                    Language: 'Idioma',
                    'Enter your credentials to continue':
                        'Ingrese sus credenciales para continuar',
                    'Hi, Welcome Back': '¡Hola, bienvenido de nuevo!',
                    Dashboard: 'Tablero de control',
                    Contestation: 'Disputa',
                    History: 'Historial',
                    Disputes: 'Disputas',
                    'Good Morning': 'Buenos días',
                    Logout: 'Cerrar sesión',
                    'Account Settings': 'Configuración de la cuenta',
                    'Create new account': 'Crear una nueva cuenta',
                    'Account created. Redirecting to login.':
                        'Cuenta creada. Redireccionando a la página de inicio de sesión',
                    'Name card': 'Nombre de la tarjeta',
                    'Card number': 'Número de tarjeta',
                    'Expiration date': 'Fecha de vencimiento',
                    CVV: 'CVV',
                    'Import a card to continue':
                        'Importar una tarjeta para continuar',
                    'Save card': 'Guardar tarjeta',
                    'Paid with': 'Pagado con',
                    'No menu/promo for this sale':
                        'Sin menú/promoción para esta venta',
                    Products: 'Productos',
                    ' products': ' productos',
                    '1 product': '1 producto',
                    VAT: 'IVA',
                    Name: 'Nombre',
                    Price: 'Precios',
                    Description: 'Descripción',
                    'Selected Product Details':
                        'Detalles del producto seleccionado',
                    'Stock Machine': 'Existencias de maquinaria',
                    'Select a machine': 'Seleccione una máquina',
                    'Previous credit': 'Crédito anterior',
                    'Current credit': 'Crédito actual',
                    'Client number': 'Número de cliente',
                    'Contact less': 'Sin contacto',
                    'Credit card': 'Tarjeta de crédito',
                    'Mobile app': 'Aplicación móvil',
                    'Private card': 'Tarjeta privada',
                    'Digital luncheon voucher': 'Vale de almuerzo digital',
                    'Sale details': 'Detalles de venta',
                    '30$': '30€',
                    '40$': '40€',
                    '50$': '50€',
                    '100$': '100€',
                    'See dispute': 'Ver disputa',
                    'Sales history': 'Historial de ventas',
                    'Purchase history': 'Historial de compras',
                    'Refills history': 'Historial de recargas',
                    discounted: 'con descuento',
                    'Refill amount': 'Cantidad de recarga',
                    'Current total credit': 'Crédito total actual',
                    'See more': 'Ver más',
                    refillCard: 'Recargar tarjeta privada',
                    refillAlready: 'Recarga en curso, por favor espere.',
                    and: 'y',
                    'in participation': 'en participación',
                    'Total product bought': 'Total de productos comprados',
                    'Total money spent': 'Dinero total gastado',
                    'Total sales': 'Ventas totales',
                    'Account created. Please check your mail to activate your account':
                        'Cuenta creada. Por favor, compruebe su correo para activar su cuenta',
                    'No sale found': 'No se encontraron ventas',
                    'Payment methods list': 'Lista de métodos de pago',
                    'Add a payment method': 'Añadir un método de pago',
                    'Add payment method': 'Agregar método de pago',
                    'Meal voucher Conecs': 'Vale de comida Conecs',
                    Profile: 'Perfil',
                    'Save details': 'Guardar detalles',
                    'Reset password': 'Restablecer contraseña',
                    'Account from': 'Cuenta de',
                    'Phone number': 'Número de teléfono',
                    'E-mail address': 'Dirección de correo electrónico',
                    'Upload picture': 'Cargar imagen',
                    'After refund credit': 'Crédito después de reembolso',
                    'Before refund credit': 'Crédito antes de reembolso',
                    Reason: 'Motivo',
                    Refund: 'Reembolso',
                    Close: 'Cerrar',
                    'Are you sure you want to delete this payment method ?':
                        '¿Estás seguro de que deseas eliminar este método de pago?',
                    Delete: 'Eliminar',
                    Cancel: 'Cancelar',
                    'Your card has been registered.':
                        'Tu tarjeta ha sido registrada.',
                    Hello: 'Hola',
                    Refill: 'Recarga',
                    registerError:
                        'Se ha producido un error al registrar su tarjeta',
                    successRefill:
                        'Su solicitud de recarga se ha procesado correctamente',
                    failedRefill:
                        'Su pago ha fallado, por favor inténtelo de nuevo',
                    paymentSuccess: 'Su recarga se ha completado con éxito',
                    paymentFailed: 'Su recarga ha sido rechazada',
                    Reload: 'Recarga',
                    punctual: 'Recarga puntual',
                    'Your refill request has been successfully processed':
                        'Su solicitud de recarga se ha tramitado correctamente',
                    'Your payment failed, please try again':
                        'Su pago falló, por favor inténtelo de nuevo',
                    'Bonus of 12$': 'Bono de 12€',
                    'Bonus of 5$': 'Bono de 5€',
                    'Created successfully': 'Creado exitosamente',
                    'Updated successfully': 'Actualizado exitosamente',
                    'Deleted successfully': 'Eliminado exitosamente',
                    'After refill credit': 'Crédito después de recarga',
                    'Your account has been successfully activated!':
                        '¡Tu cuenta ha sido activada exitosamente!',
                    'You will be redirected to the login page in a few seconds...':
                        'Serás redirigido/a a la página de inicio de sesión en unos segundos...',
                    'Activation Error': 'Error de activación',
                    "I'm willing to receive every week the newsletter from ":
                        'Deseo recibir el boletín semanal de ',
                    "I'm willing to receive punctual promotions (sales, operations, Antiwaste)":
                        'Deseo recibir promociones puntuales (ventas, operaciones, Antigaspi...).',
                    "I'm willing to receive targeted emails according to my browsing data and my interests":
                        'Deseo recibir correos electrónicos dirigidos según mis datos de navegación e intereses.',
                    'No dispute found': 'No se encontró ninguna disputa',
                    'Old password': 'Contraseña antigua',
                    'New password': 'Nueva contraseña',
                    'Reset your password': 'Restablecer su contraseña',
                    'Confirm password': 'Confirmación de nueva contraseña',
                    'Please validate the login request by clicking the button':
                        'Por favor, valide la solicitud de inicio de sesión haciendo clic en el botón',
                    'Send connection request': 'Enviar solicitud de conexión',
                    'Logged in to machine successfully! You are being redirected to the app...':
                        '¡Conectado a la máquina con éxito! Se le está redirigiendo a la aplicación...',
                    'You need to register at least one paiement method to use the app.':
                        'Debe registrar al menos un método de pago para usar la aplicación.',
                    'Unable to registered your card, please check the information. Please try again.':
                        'No se ha podido registrar su tarjeta, por favor compruebe la información. Por favor, inténtelo de nuevo.',
                    'No refill found': 'No se encontró ninguna recarga',
                    Payment: 'Pago',
                    'Invalid card': 'Tarjeta inválida',
                    'Contact operator of this sale':
                        'Contactar al operador de esta venta',
                    'The application cannot find which client you are trying to create an account for. Please initiate the creation via a QR code or a link sent to you by an operator.':
                        'La aplicación no puede encontrar para qué cliente está intentando crear una cuenta. Inicie la creación a través de un código QR o un enlace enviado por un operador.',
                },
            },
        },
        fallbackLng: 'en-GB',
        debug: process.env.NODE_ENV === 'development',
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false, // we use content as keys
        interpolation: {
            escapeValue: false,
        },
    })
export default i18n
