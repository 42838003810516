export const api = {
    CARDDETAILS: 'adyen_proxy/paymentMethods/',
    DASHBOARD: 'dashboard/',
    DELETECARD: 'adyen_proxy/storedPaymentMethods/',
    KONSOLE_COLORS: 'colors_parameters/',
    MACHINEGRIDITEM: 'machine_grid_item/',
    PAYMENTS: 'adyen_proxy/payments/',
    PURCHASES: 'sales/',
    QR_CODE: 'send_notification_to_machine/',
    REFILL: 'adyen_proxy/refill_payments/',
    REFILL_HISTORY: 'credit_refills/',
    REFILL_STATUS: 'credit_refill_status/',
    SESSIONS: 'adyen_proxy/sessions/',
    VALIDATE: 'validate-activation-token/',

    auth: {
        LOGIN: 'token/',
        REFRESH_TOKENS: 'token/refresh/',
        REGISTER: 'register/',
    },

    user: {
        PROFILE: 'me/',
        UPDATE_PASSWORD: 'password/change/',
    },
}
